var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { ConfigConsumer } from '../config-provider';
function generator({ suffixCls, tagName }) {
    return (BasicComponent) => {
        return class Adapter extends React.Component {
            constructor() {
                super(...arguments);
                this.renderComponent = ({ getPrefixCls }) => {
                    const { prefixCls: customizePrefixCls } = this.props;
                    const prefixCls = getPrefixCls(suffixCls, customizePrefixCls);
                    return <BasicComponent prefixCls={prefixCls} tagName={tagName} {...this.props}/>;
                };
            }
            render() {
                return <ConfigConsumer>{this.renderComponent}</ConfigConsumer>;
            }
        };
    };
}
class Basic extends React.Component {
    render() {
        const _a = this.props, { prefixCls, className, children, tagName: CustomElement } = _a, others = __rest(_a, ["prefixCls", "className", "children", "tagName"]);
        const classString = classNames(className, prefixCls);
        return (<CustomElement className={classString} {...others}>
        {children}
      </CustomElement>);
    }
}
class BasicLayout extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { siders: [] };
    }
    getChildContext() {
        return {
            siderHook: {
                addSider: (id) => {
                    this.setState(state => ({
                        siders: [...state.siders, id],
                    }));
                },
                removeSider: (id) => {
                    this.setState(state => ({
                        siders: state.siders.filter(currentId => currentId !== id),
                    }));
                },
            },
        };
    }
    render() {
        const _a = this.props, { prefixCls, className, children, hasSider, tagName: CustomElement } = _a, others = __rest(_a, ["prefixCls", "className", "children", "hasSider", "tagName"]);
        const classString = classNames(className, prefixCls, {
            [`${prefixCls}-has-sider`]: hasSider || this.state.siders.length > 0,
        });
        return (<CustomElement className={classString} {...others}>
        {children}
      </CustomElement>);
    }
}
BasicLayout.childContextTypes = {
    siderHook: PropTypes.object,
};
const Layout = generator({
    suffixCls: 'layout',
    tagName: 'section',
})(BasicLayout);
const Header = generator({
    suffixCls: 'layout-header',
    tagName: 'header',
})(Basic);
const Footer = generator({
    suffixCls: 'layout-footer',
    tagName: 'footer',
})(Basic);
const Content = generator({
    suffixCls: 'layout-content',
    tagName: 'main',
})(Basic);
Layout.Header = Header;
Layout.Footer = Footer;
Layout.Content = Content;
export default Layout;
